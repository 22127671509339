<ion-app>
  <ion-menu side="start" menuId="main-menu" swipe-gesture="false" content-id="main-content" [swipeGesture]="false">
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-title>
          <img src="../assets/img/divinea.svg" alt="">
          DWS
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <h1>DWS PreOrders</h1>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content" [swipeGesture]="false"></ion-router-outlet>
</ion-app>
