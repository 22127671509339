/* eslint-disable @typescript-eslint/no-inferrable-types */
// tslint:disable:ban-types
// eslint-disable max-len
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { resolve } from '@util/resource/resolve';
import { clone as _clone, merge as _merge } from 'lodash';
import { Injector } from '@angular/core';
import { map } from 'rxjs/operators';

export interface Resource {
  headers(headers: { [header: string]: string|string[] }): Resource;
  params(qp: {[param: string]: string|string[]|unknown }): Resource;
  basic(userOrToken: string, password?: string): Resource;
  bearer(token: string): Resource;
  authorization(type: 'bearer' | 'basic' | string, token: string): Resource;

  get<T>(response?: 'json'): Observable<T>;
  get(response?: 'text'): Observable<string>;
  get(response?: 'blob'): Observable<Blob>;
  get(response?: 'arraybuffer'): Observable<ArrayBuffer>;

  post<T>(body: any, options: { observe: 'response' }): Observable<HttpResponse<T>>;
  post<T>(body?: any, options?: { observe: 'body' }): Observable<T>;

  put<T>(body?: any): Observable<T>;
  head(): Observable<any>;
  delete(): Observable<any>;
  resolve(): string;
}

class ResourceImplementation implements Resource {
  private readonly path: string;
  private authorizationToken: string = '';
  private queryParams: { [k: string]: string|string[] } = {};
  private headerParams: { [k: string]: string|string[] } = {};
  // @ts-ignore
  get http(): HttpClient {
    const injector: Injector =  (window as any).injector;
    return injector.get(HttpClient);
  }

  constructor(
    path: string
  ) {
    this.path = path;
  }

  authorization(type: 'bearer' | 'basic' | string, token: string) {
    type = type[0].toUpperCase() + type.substr(1).toLowerCase();
    this.authorizationToken =  `${type} ${token}`;
    return this;
  }

  basic(userOrToken: string, password?: string): Resource {
    if (password === undefined) return this.authorization('basic', userOrToken);
    else return this.authorization('basic', btoa(`${userOrToken}:${password}`));
  }

  bearer(token: string): Resource {
    return this.authorization('bearer', token);
  }

  delete(): Observable<any> {
    return this.http.delete(this.makeUrl(), { headers: this.makeHeaders() });
  }

  get<T>(response?: 'json'): Observable<T>;
  get(response?: 'text'): Observable<string>;
  get(response?: 'blob'): Observable<Blob>;
  get(response?: 'arraybuffer'): Observable<ArrayBuffer>;
  // eslint-disable-next-line max-len
  get<T = any>(responseType?: 'json' | 'text' | 'blob' | 'arraybuffer'): Observable<T> | Observable<string> | Observable<Blob> | Observable<ArrayBuffer> {
    const headers = this.makeHeaders(),
          opts = { headers };

    switch (responseType) {
      case 'blob':
        return this.http.get(this.makeUrl(), { ...opts, responseType }) as Observable<Blob>;
      case 'arraybuffer':
        return this.http.get(this.makeUrl(), { ...opts, responseType }) as Observable<ArrayBuffer>;
      case 'text':
        return this.http.get(this.makeUrl(), { ...opts, responseType }) as Observable<string>;
      default:
        return this.http.get<T>(this.makeUrl(), { ...opts, responseType: 'json' }) as Observable<T>;
    }
  }

  head(): Observable<any> {
    return this.http.head(this.makeUrl(), { headers: this.makeHeaders() });
  }

  headers(headers: { [p: string]: string | string[] }): Resource {
    this.headerParams = _clone(headers);
    return this;
  }

  params(p: { [p: string]: string | string[] }): Resource {
    this.queryParams = _clone(p);
    return this;
  }

  post<T>(body?: any, options: { observe: 'response' | 'body' } = { observe: 'body'} ): Observable<T | HttpResponse<T>> {
    return this.http
               .post<T>(this.makeUrl(), body, { headers: this.makeHeaders(), observe: 'response' })
      .pipe(
        map(response => {
          if (options.observe === 'body') return response.body as T;
          else return response;
        })
      );
  }

  put<T>(body?: any): Observable<T> {
    return this.http.put<T>(this.makeUrl(), body, { headers: this.makeHeaders() });
  }

  resolve(): string {
    return this.makeUrl();
  }

  private makeUrl() {
    return resolve(this.path, _merge({}, this.queryParams));
  }

  private makeHeaders(): HttpHeaders {
    if (!this.headerParams) return new HttpHeaders();
    const headers = Object
      .keys(this.headerParams)
      .reduce((h, k) => h.append(k, this.headerParams[k]), new HttpHeaders());
    if (this.authorizationToken) return headers.append('Authorization', this.authorizationToken);
    else return headers;
  }

}

function resource(path: string): Resource {
  return new ResourceImplementation(path);
}

export { resource };
