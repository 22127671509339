import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WineryInfoService } from '@app/shared/services/winery-info.service';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '@util/locale';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private route: ActivatedRoute,
    private wineryInfo: WineryInfoService,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe((p: any) => {
      const { wineryId, channelId } = p || {};
      if (wineryId && channelId) {
       this.wineryInfo.load(wineryId, channelId);
      }
    });
    const l = locales();
    let def = 'it';
    if (l.stored) def = l.stored;
    translate.setDefaultLang(def);
    translate.use(l.locale);
    translate.onDefaultLangChange.subscribe(ev => {
      console.log('lang change to', ev);
      localStorage.setItem('locale', ev.lang);
      translate.use(ev.lang);
      if (l.stored !== ev.lang) location.reload();
    });
  }
}
