import { AppEnvironment, environment as common } from '@env/environment.common';

export const environment: AppEnvironment = {
  production: true,
  name: 'staging',
  default: 'preorders',
  features: {
    ...common.features
  },
  values: {
    ...common.values
  },
  hosts: {
    preorders: {
      ...common.hosts.preorders,
      host: 'api.preorders-staging.divinea.com',
      protocol: 'https',
      port: '',
    },
    ws: {
      ...common.hosts.ws,
      host: 'api-crm-staging.divinea.com',
      protocol: 'https',
      port: '',
    }
  }
};
