import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import { resource } from '@util/resource';

export interface WineryInfo {
  name: string;
  avatar: string;
  privacyStatementUrl: string;
}

@Injectable({
  providedIn: 'root'
})
// Os atributos wineryId e channelId estão aqui para manter o wineryId, channelId na troca de paginas
// Por favor não seja esperto
export class WineryInfoService {
  public wineryId: string;
  public channelId: string;
  private wineryInfo$: ReplaySubject<WineryInfo> = new ReplaySubject<WineryInfo>(1);

  constructor() {
  }

  getWineryDetail(): Observable<WineryInfo> {
    return this.wineryInfo$;
  }

  public load(wineryId: string, channelId: string) {
    this.wineryId = wineryId;
    this.channelId = channelId;

    resource('preorders://winery/wineryId')
      .params({wineryId})
      .get<WineryInfo>()
      .subscribe(wineryInfo => this.wineryInfo$.next(wineryInfo));
  }
}
