function getBrowserLocales(options = {}): string[] | undefined {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly
      ? trimmedLocale.split(/[-_]/)[0]
      : trimmedLocale;
  });
}


function locales(): { locale: string; stored: string; browser: string } {
  const isLocaleSupported = {
    it: true,
    en: true
  } as any;

  let storedLocale = localStorage.getItem('locale');
  let deducedLocale = 'it';

  let browserLocale = '';
  const browserLocales = getBrowserLocales({ languageCodeOnly: true });
  if (browserLocales && browserLocales.length) browserLocale = browserLocales[0];

  if (storedLocale && isLocaleSupported[storedLocale]) {
    deducedLocale = storedLocale;
  } else {
    if (!isLocaleSupported[browserLocale]) deducedLocale = 'it';
    localStorage.setItem('locale', deducedLocale);
    storedLocale = deducedLocale;
  }

  return { locale: deducedLocale, stored: storedLocale, browser: browserLocale };
}

function locale() {
  return locales().locale;
}

export { locales, locale };
